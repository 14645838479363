import Link from 'next/link';
import { GearThemePrimaryImage } from '../../svgImages/GearImage';
import { PlusThemePrimaryImage } from '../../svgImages/PlusImage';
import type { ButtonLinkProps } from '../types';

export const ButtonLinkSecondary: React.FC<
  ButtonLinkProps & {
    prefixIcon?: 'plus' | 'gear';
  }
> = ({ text, href, width, prefixIcon = null, ...attr }) => (
  <Link
    {...attr}
    href={href}
    passHref
    className={
      width === 'fit'
        ? 'tw-group tw-relative tw-flex tw-min-h-[3rem] tw-w-fit tw-items-center tw-justify-center tw-rounded-8 tw-px-[1rem] tw-py-[0.75rem]'
        : 'tw-group tw-relative tw-flex tw-min-h-[3rem] tw-w-full tw-items-center tw-justify-center tw-rounded-8 tw-px-[1rem] tw-py-[0.75rem]'
    }
  >
    <div className="tw-absolute tw-inset-0 tw-rounded-8 tw-bg-base-100 tw-transition-colors group-hover:tw-bg-accent-secondary" />
    <div className="tw-absolute tw-inset-0 tw-rounded-8 tw-border-[2px] tw-border-accent-primary" />
    <div className="tw-relative tw-flex tw-w-fit tw-items-center tw-gap-[0.25rem]">
      {prefixIcon === 'plus' && (
        <PlusThemePrimaryImage alt="" className="tw-size-[1rem]" />
      )}
      {prefixIcon === 'gear' && (
        <GearThemePrimaryImage alt="" className="tw-size-[1rem]" />
      )}
      <div className="tw-text-button tw-text-accent-primary">{text}</div>
    </div>
  </Link>
);
