import Image from 'next/image';
import IconThemePrimary from './plus_theme_primary.svg?url';
import IconWhite from './plus_white.svg?url';
import type { SvgImageProps } from '../types';

export const PlusThemePrimaryImage: React.FC<SvgImageProps> = ({
  alt,
  ...props
}) => <Image src={IconThemePrimary} alt={alt} {...props} />;

export const PlusWhiteImage: React.FC<SvgImageProps> = ({ alt, ...props }) => (
  <Image src={IconWhite} alt={alt} {...props} />
);
